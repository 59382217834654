import 'remixicon/fonts/remixicon.css'

function WhatWeDo(props) {
    return (
        <div className="what-we-do-wrapper">
          <h1>What We Do</h1>
            <div className="one-what-we-do">
              <div className='icon-h3-wrapper'>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="32" height="32"><path d="M15.1986 9.94435C14.7649 9.53358 14.4859 8.98601 14.4085 8.39371L14.0056 5.31126L11.275 6.79711C10.7503 7.08262 10.1433 7.17876 9.55608 7.06936L6.49998 6.50003L7.06931 9.55612C7.17871 10.1434 7.08257 10.7503 6.79707 11.275L5.31121 14.0056L8.39367 14.4085C8.98596 14.4859 9.53353 14.7649 9.94431 15.1986L12.0821 17.4555L13.4178 14.6485C13.6745 14.1091 14.109 13.6745 14.6484 13.4179L17.4555 12.0821L15.1986 9.94435ZM15.2238 15.5078L13.0111 20.1579C12.8687 20.4572 12.5107 20.5843 12.2115 20.4419C12.1448 20.4102 12.0845 20.3664 12.0337 20.3127L8.49229 16.574C8.39749 16.4739 8.27113 16.4095 8.13445 16.3917L3.02816 15.7242C2.69958 15.6812 2.46804 15.3801 2.51099 15.0515C2.52056 14.9782 2.54359 14.9074 2.5789 14.8425L5.04031 10.3191C5.1062 10.198 5.12839 10.0579 5.10314 9.92241L4.16 4.85979C4.09931 4.53402 4.3142 4.22074 4.63997 4.16005C4.7126 4.14652 4.78711 4.14652 4.85974 4.16005L9.92237 5.10319C10.0579 5.12843 10.198 5.10625 10.319 5.04036L14.8424 2.57895C15.1335 2.42056 15.4979 2.52812 15.6562 2.81919C15.6916 2.88409 15.7146 2.95495 15.7241 3.02821L16.3916 8.13449C16.4095 8.27118 16.4739 8.39754 16.5739 8.49233L20.3127 12.0337C20.5533 12.2616 20.5636 12.6414 20.3357 12.8819C20.2849 12.9356 20.2246 12.9794 20.1579 13.0111L15.5078 15.2238C15.3833 15.2831 15.283 15.3833 15.2238 15.5078ZM16.0206 17.4349L17.4348 16.0207L21.6775 20.2633L20.2633 21.6775L16.0206 17.4349Z" fill="rgba(40,82,154,1)"></path></svg>
                <h3>Facelifts</h3>
              </div>
              <div className='what-we-do-p'>
                <p>Let us help you revitalize your online presence by giving your website a modern and captivating new look that engages visitors and boosts your online success.</p> 
              </div>
              
            </div>

            <div className="one-what-we-do">
              <div className='icon-h3-wrapper'>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="32" height="32"><path d="M12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2ZM12 17C11.355 17 10.7386 16.8779 10.1725 16.6555L7.93604 18.8923C9.12707 19.5961 10.5164 20 12 20C13.4836 20 14.8729 19.5961 16.064 18.8923L13.8275 16.6555C13.2614 16.8779 12.645 17 12 17ZM4 12C4 13.4836 4.40386 14.8729 5.10765 16.064L7.34451 13.8275C7.12213 13.2614 7 12.645 7 12C7 11.355 7.12213 10.7386 7.34451 10.1725L5.10765 7.93604C4.40386 9.12707 4 10.5164 4 12ZM18.8923 7.93604L16.6555 10.1725C16.8779 10.7386 17 11.355 17 12C17 12.645 16.8779 13.2614 16.6555 13.8275L18.8923 16.064C19.5961 14.8729 20 13.4836 20 12C20 10.5164 19.5961 9.12707 18.8923 7.93604ZM12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9ZM12 4C10.5164 4 9.12707 4.40386 7.93604 5.10765L10.1725 7.34451C10.7386 7.12213 11.355 7 12 7C12.645 7 13.2614 7.12213 13.8275 7.34451L16.064 5.10765C14.8729 4.40386 13.4836 4 12 4Z" fill="rgba(40,82,154,1)"></path></svg>
                <h3>Project Resuce</h3>
              </div>
            <div className='what-we-do-p'>
              <p>We can help rescue your struggling projects and bring them back to life. We specialize in salvaging and revitalizing projects that have gotten off track, turning them into thriving online assets that meet your goals.</p>           
            </div>

            </div>

            <div className="one-what-we-do">
              <div className='icon-h3-wrapper'>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="32" height="32"><path d="M7.05033 14.1214L4.929 16.2427L7.75743 19.0711L19.0711 7.75743L16.2427 4.929L14.1214 7.05033L15.5356 8.46454L14.1214 9.87875L12.7072 8.46454L11.293 9.87875L12.7072 11.293L11.293 12.7072L9.87875 11.293L8.46454 12.7072L9.87875 14.1214L8.46454 15.5356L7.05033 14.1214ZM16.9498 2.80768L21.1925 7.05033C21.583 7.44085 21.583 8.07401 21.1925 8.46454L8.46454 21.1925C8.07401 21.583 7.44085 21.583 7.05033 21.1925L2.80768 16.9498C2.41716 16.5593 2.41716 15.9261 2.80768 15.5356L15.5356 2.80768C15.9261 2.41716 16.5593 2.41716 16.9498 2.80768ZM14.1214 18.3635L15.5356 16.9493L17.7781 19.1918H19.1923V17.7776L16.9498 15.5351L18.364 14.1209L20.9997 16.7566V20.9999H16.7578L14.1214 18.3635ZM5.63597 9.87812L2.80754 7.04969C2.41702 6.65917 2.41702 6.02601 2.80754 5.63548L5.63597 2.80705C6.02649 2.41653 6.65966 2.41653 7.05018 2.80705L9.87861 5.63548L8.4644 7.04969L6.34308 4.92837L4.92886 6.34259L7.05018 8.46391L5.63597 9.87812Z" fill="rgba(40,82,154,1)"></path></svg>
                <h3>New Projects</h3>
              </div>
            <div className='what-we-do-p'></div>
              <p>From ideation to creation, we are your web development partners in bringing your visions to life and building website solutions tailored to your unique needs and aspirations.</p>
            </div>

        </div>
    )

};

export default WhatWeDo;